import { computed, Ref } from "vue";
import { createFieldState, FormFields, FormParams } from "@/domains/app/forms";
import { Requester, useRequester } from "./requesters";
import { useUser } from "@/domains/auth/user";
import { ROLE_ADMIN, ROLE_DELEGATE } from "../users/users";

export type RequesterFormState = {
    formArgs: FormParams;
    data: Ref<Requester | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the requester in the form
 * @returns a form state
 */
export function useRequesterForm(id?: number): RequesterFormState {
    const { data: requester, save, del: remove, apiErrors } = useRequester(id);

    const fields = getFields(requester);

    const formArgs: FormParams = {
        title: "Création d'un demandeur",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un demandeur";
        formArgs.driver.delete = remove;
        const user = useUser().user;
        formArgs.getDisabled = () =>
            computed(
                () =>
                    requester.value !== null &&
                    requester.value.id > 0 &&
                    user.value !== null &&
                    user.value.id !== requester.value.user_id &&
                    !user.value.roles.includes(ROLE_ADMIN)
            );
    }

    const formState: RequesterFormState = {
        formArgs,
        data: requester,
    };

    return formState;
}

/**
 * Map requester props to form fields
 *
 * @param requester requester state
 * @returns form fields
 */
function getFields(requester: Ref<Requester | null>): FormFields {
    const user = useUser().user;
    const isDepartmentRequester = computed<boolean>({
        set(b: boolean) {
            if (requester.value && user.value)
                requester.value.department_id = b
                    ? user.value?.department_id
                    : null;
        },
        get: () =>
            requester.value !== null &&
            requester.value.department_id !== null &&
            requester.value.department_id === user.value?.department_id,
    });

    return [
        {
            label: "Nom du demandeur",
            type: "text",
            name: "name",
            getState: () => createFieldState<string>(requester, "name"),
        },
        {
            label: "Membre",
            type: "boolean",
            name: "is_member",
            getState: () => createFieldState<boolean>(requester, "is_member"),
        },
        {
            label: "Demandeur visible dans le départment",
            type: "boolean",
            name: "department_id",
            getState: () => isDepartmentRequester,
            helpText:
                "En tant que délégué, vous pouvez créer un demandeur accessible par vous ou bien par tous les membres de votre département",
            getVisibility: () =>
                computed(
                    () =>
                        user.value !== null &&
                        user.value.roles.includes(ROLE_DELEGATE) &&
                        (requester.value?.department_id === null ||
                            requester.value?.department_id ===
                                user.value.department_id) &&
                        requester.value.user_id === user.value.id
                ),
            defaultHidden: false,
            defaultVisible: () =>
                computed(() => requester.value?.department_id !== null),
        },
    ];
}
