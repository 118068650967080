/* eslint-disable prettier/prettier */
import { sheetStatisticsColumns } from "./config";
import { useRelations } from "./relations";
import { SheetStatisticsColumns } from "./statistics";

export function getRelationLabel(
    relation: keyof SheetStatisticsColumns,
    relationId: string
): string | null {
    const relations = useRelations();
    if (
        relations[relation] === undefined ||
        relations[relation][relationId] === undefined
    )
        return null;
    if (relation === "shot_id") return relations[relation][relationId].label;
    return relations[relation][relationId].label.split("(")[0].trim();
}

export function getIntervalLabel(
    col: keyof SheetStatisticsColumns,
    interval: [number | null, number | null]
): string | null {
    if (
        sheetStatisticsColumns[col] === undefined ||
        !(sheetStatisticsColumns[col]?.type === "number-interval")
    )
        return null;
    const config = sheetStatisticsColumns[col] as Extract<
        SheetStatisticsColumns[number | string],
        { type: "number-interval" }
    >;
    const i = interval;
    let r;
    if (i[0] === null && i[1] === null) {
        r = "";
    } else if (i[0] === null) {
        r = "-" + i[1];
    } else if (i[1] === null) {
        r = i[0] + "+";
    } else {
        r = i[0] + " - " + i[1];
    }
    return r ? r + (config.unit ? " " + getUnit(col, false) : "") : "";
}

export function getUnit(col: string | number, isTotal: boolean): string {
    const u = sheetStatisticsColumns[col]?.unit;
    return u ? (typeof u === "string" ? u : u(isTotal)) : "";
}

export function getSeasonBound(fromTimestamp: number): {
    start: number;
    end: number;
    label: string;
} {
    const start = new Date(fromTimestamp);
    if (start.getUTCMonth() < 3)
        start.setUTCFullYear(start.getUTCFullYear() - 1);
    start.setUTCMonth(3, 1);
    start.setUTCHours(0, 0, 0, 0);

    return {
        start: start.getTime(),
        end: start.getTime() + 365 * 24 * 60 * 60 * 1000 - 1,
        label: start.getFullYear() + "/" + (start.getFullYear() + 1),
    };
}
