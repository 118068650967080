import { computed, Ref } from "vue";
import {
    createFieldState,
    FieldConfig,
    FormFields,
    FormParams,
} from "@/domains/app/forms";
import { Gic, useGic } from "./gic";
import { useUser } from "@/domains/auth/user";
import { ROLE_ADMIN, ROLE_DELEGATE } from "../users/users";

export type GicFormState = {
    formArgs: FormParams;
    data: Ref<Gic | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the gic in the form
 * @returns a form state
 */
export function useGicForm(id?: number): GicFormState {
    const { data: gic, save, del, apiErrors } = useGic(id);

    const fields = getFields(gic);

    const formArgs: FormParams = {
        title: "Création d'un GIC",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un GIC";
        formArgs.driver.delete = del;
        const user = useUser().user;
        formArgs.getDisabled = () =>
            computed(
                () =>
                    (gic.value?.department_id !== null &&
                        (user.value?.department_id !==
                            gic.value?.department_id ||
                            (!user.value?.roles.includes(ROLE_DELEGATE) &&
                                !user.value?.roles.includes(ROLE_ADMIN)))) ||
                    (gic.value?.department_id !== null &&
                        user.value?.id !== gic.value?.user_id)
            );
    }

    const formState: GicFormState = {
        formArgs,
        data: gic,
    };

    return formState;
}

/**
 * Map gic props to form fields
 *
 * @param gic gic state
 * @returns form fields
 */
function getFields(gic: Ref<Gic | null>): FormFields {
    const user = useUser().user;

    const isDepartmentGic = computed<boolean>({
        set(b: boolean) {
            if (gic.value && user.value)
                gic.value.department_id = b ? user.value?.department_id : null;
        },
        get: () =>
            gic.value !== null &&
            gic.value.department_id !== null &&
            gic.value.department_id === user.value?.department_id,
    });

    const depFieldConfig: FieldConfig<boolean> = {
        label: "Gic visible dans le départment",
        type: "boolean",
        name: "department_id",
        getState: () => isDepartmentGic,
        helpText:
            "En tant que délégué, vous pouvez créer un gic accessible par vous ou bien par tous les membres de votre département",
        getVisibility: () =>
            computed(
                () =>
                    user.value !== null &&
                    user.value.roles.includes(ROLE_DELEGATE) &&
                    (gic.value?.department_id === null ||
                        gic.value?.department_id ===
                            user.value.department_id) &&
                    gic.value.user_id === user.value.id
            ),
        defaultHidden: false,
        defaultVisible: () => computed(() => gic.value?.department_id !== null),
    };

    return [
        {
            label: "Nom du gic",
            type: "text",
            name: "label",
            getState: () => createFieldState<string>(gic, "label"),
        },
        depFieldConfig,
    ];
}
