import { computed, ComputedRef, Ref, ref } from "vue";
import { request } from "@/domains/api/api";
import { Sheet } from "@/domains/cruds/sheets/sheets";
import { SheetFilterColumns, useStatistics } from "./statistics";
import { useLoading } from "../app/loading";

const _filters = ref<Filter[]>([]);
let abortController = new AbortController();

export type PartialSheet = {
    [K in keyof Sheet]?: Sheet[K];
} & {
    id: Sheet["id"];
};

export type ComparableFilterType = "<" | ">";
export type EqualityFilterType = "=" | "≠";
export type SeasonSpecificFilterType = "range";
export type FilterType =
    | ComparableFilterType
    | EqualityFilterType
    | SeasonSpecificFilterType;

export type Filter = {
    type: FilterType;
    col: keyof SheetFilterColumns;
    value: string;
    visibleValue: string;
};

type ParsedFilters = Record<string /* col + [i] + [type]|[value] */, string>;

const errors = ref<string>("");

function removeFilter(filter: Filter) {
    _filters.value = _filters.value.filter(
        (f) =>
            !(
                f.col === filter.col &&
                f.type === filter.type &&
                f.value === filter.value &&
                f.visibleValue === f.visibleValue
            )
    );
}

function appendFilter(
    column: keyof SheetFilterColumns,
    filterType: FilterType,
    filterValue: string,
    visibleValue: string
) {
    const f = {
        type: filterType,
        col: column,
        value: filterValue,
        visibleValue,
    };
    _filters.value.push(f);
    return f;
}

function fetchSheets(page?: number) {
    abortController.abort();
    abortController = new AbortController();
    if (page === undefined) page = 0;
    const limit = 10;
    const offset = page * limit;

    const { setLoading } = useLoading("statistics.sheets");
    setLoading(true);

    return request<{ data: PartialSheet[]; total: number }>(
        "statistics/sheets",
        "get",
        (err) => (errors.value = err.message),
        { ...getParsedFilters(), limit, offset },
        { signal: abortController.signal }
    ).finally(() => {
        setLoading(false);
    });
}

export function getParsedFilters(filters?: Filter[]): ParsedFilters {
    if (filters === undefined) {
        filters = _filters.value;
    }
    const counters: Record<Filter["col"], number> = {};
    return filters.reduce<ParsedFilters>((acc, filter) => {
        const i = counters[filter.col] || 0;
        acc[`${filter.col}[${i}][filter]`] = encodeURIComponent(
            filter.type === "≠" ? "!=" : filter.type
        );
        acc[`${filter.col}[${i}][value]`] = "" + filter.value;
        counters[filter.col] = i + 1;
        return acc;
    }, {});
}

type SheetsNavigationState = {
    sheets: ComputedRef<PartialSheet[]>;
    total: ComputedRef<null | number>;
    nextPage: () => void;
    prevPage: () => void;
    goToPage: (p: number) => void;
};

const startOfSeason = new Date();
if (startOfSeason.getUTCMonth() < 3)
    startOfSeason.setUTCFullYear(new Date().getFullYear() - 1, 3, 1);
else startOfSeason.setUTCFullYear(new Date().getFullYear(), 3, 1);
startOfSeason.setUTCHours(0);

_filters.value = [
    {
        col: "season_id",
        type: "=",
        value: "" + startOfSeason.getTime(),
        visibleValue:
            startOfSeason.getFullYear() +
            "/" +
            (startOfSeason.getFullYear() + 1),
    },
];

export function useFilters(immediate = false): {
    appendFilter: (
        column: keyof SheetFilterColumns,
        filterType: FilterType,
        filterValue: string,
        visibleValue: string
    ) => Filter;
    removeFilter: (f: Filter) => void;
    useSheetsNaviationState: () => SheetsNavigationState;
    filters: Ref<Filter[]>;
} {
    const results = ref<PartialSheet[]>([]);
    const totalFiltered = ref<null | number>(null);
    let navigationState: null | SheetsNavigationState = null;

    function handleResponse(
        rs: {
            data: PartialSheet[];
            total: number;
        } | null
    ) {
        if (rs === null) {
            results.value = [];
            totalFiltered.value = null;
        } else {
            results.value = rs.data;
            totalFiltered.value = rs.total;
        }
    }

    if (immediate) fetchSheets().then((data) => handleResponse(data));

    const { setFilters: setStatsFilters } = useStatistics(immediate);

    return {
        appendFilter: (...args) => {
            const existing = _filters.value.find(
                (filter) =>
                    filter.col === args[0] &&
                    filter.type === args[1] &&
                    filter.value === args[2]
            );
            if (existing) return existing;
            const f = appendFilter(...args);
            setStatsFilters(_filters.value);
            navigationState?.goToPage(1);
            return f;
        },
        removeFilter: (...args) => {
            removeFilter(...args);
            setStatsFilters(_filters.value);
            navigationState?.goToPage(1);
        },
        useSheetsNaviationState: () => {
            if (!navigationState) navigationState = getNavigationState(results);
            return navigationState;
        },
        filters: _filters,
    };
}

function getNavigationState(
    sheetsRef: Ref<PartialSheet[]>
): SheetsNavigationState {
    let page = 0;
    const total = ref<null | number>(null);

    fetchSheets(page).then(handleResult);

    function handleResult(rs: { data: PartialSheet[]; total: number } | null) {
        if (rs === null) return;
        total.value = rs.total;
        sheetsRef.value = rs.data;
    }

    return {
        sheets: computed(() => sheetsRef.value),
        total: computed(() => total.value),
        goToPage: (p) => {
            page = p - 1;
            fetchSheets(page).then(handleResult);
        },
        nextPage: () => {
            if (total.value === null) return;
            fetchSheets(++page).then(handleResult);
        },
        prevPage: () => {
            if (total.value === null) return;
            fetchSheets(--page).then(handleResult);
        },
    };
}
