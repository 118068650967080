import { computed, ComputedRef, reactive, watch, Directive } from "vue";

const openedLoadings: Record<string, number> = reactive({});

export const globalLoading = computed(() =>
    Object.values(openedLoadings).some((n) => n !== 0)
);

export function useLoading(key: string): {
    loading: ComputedRef<boolean>;
    setLoading: (b: boolean) => void;
} {
    return {
        loading: computed(() => openedLoadings[key] > 0),
        setLoading: (b) => {
            openedLoadings[key] = Math.max(
                (openedLoadings[key] || 0) + (b ? 1 : -1),
                0
            );
        },
    };
}

export function getLoadingPromise(key: string): Promise<void> {
    const loading = useLoading(key).loading;
    let resolve: (() => void) | null = null;

    const unwatch = watch(loading, (v) => {
        if (!v) {
            unwatch();
            if (resolve) {
                resolve();
            }
        }
    });

    return new Promise<void>((res) => {
        if (!loading.value) {
            unwatch();
            return res();
        }
        resolve = res;
    });
}

export const loadingDirective: Directive<HTMLElement, string> = {
    mounted(el, bindings) {
        const key = bindings.value;
        if (!key) return;

        const loadingEl = document.createElement("p");
        loadingEl.classList.add("loading-state");
        loadingEl.innerText = "Chargement ...";
        el.appendChild(loadingEl);

        const loadingState = useLoading(key).loading;
        watch(
            loadingState,
            (loading) => {
                if (el.tagName === "BUTTON") {
                    (el as HTMLButtonElement).disabled = loading;
                }
                el.classList.toggle("loading", loading);
            },
            { immediate: true }
        );
    },
};
