import { computed, Ref } from "vue";
import { createFieldState, FormFields, FormParams } from "@/domains/app/forms";
import { Location, useLocation } from "./locations";
import { useUser } from "@/domains/auth/user";
import { ROLE_ADMIN, ROLE_DELEGATE } from "../users/users";

export type LocationFormState = {
    formArgs: FormParams;
    data: Ref<Location | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the location in the form
 * @returns a form state
 */
export function useLocationForm(id?: number): LocationFormState {
    const { data: location, save, del: remove, apiErrors } = useLocation(id);

    const fields = getFields(location);

    const formArgs: FormParams = {
        title: "Création d'un lieu",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un lieu";
        formArgs.driver.delete = remove;
        const user = useUser().user;
        formArgs.getDisabled = () =>
            computed(
                () =>
                    location.value !== null &&
                    location.value.id > 0 &&
                    user.value !== null &&
                    user.value.id !== location.value.user_id &&
                    !user.value.roles.includes(ROLE_ADMIN)
            );
    }

    const formState: LocationFormState = {
        formArgs,
        data: location,
    };

    return formState;
}

/**
 * Map location props to form fields
 *
 * @param dep location state
 * @returns form fields
 */
function getFields(location: Ref<Location | null>): FormFields {
    const user = useUser().user;
    const isDepartmentRequester = computed<boolean>({
        set(b: boolean) {
            if (location.value && user.value)
                location.value.department_id = b
                    ? user.value?.department_id
                    : null;
        },
        get: () =>
            location.value !== null &&
            location.value.department_id !== null &&
            location.value.department_id === user.value?.department_id,
    });

    return [
        {
            label: "Nom",
            type: "text",
            name: "label",
            getState: () => createFieldState(location, "label"),
        },
        {
            label: "Lieux visible dans le départment",
            type: "boolean",
            name: "department_id",
            getState: () => isDepartmentRequester,
            helpText:
                "En tant que délégué, vous pouvez créer un lieu accessible par vous ou bien par tous les membres de votre département",
            getVisibility: () =>
                computed(
                    () =>
                        user.value !== null &&
                        user.value.roles.includes(ROLE_DELEGATE) &&
                        (location.value?.department_id === null ||
                            location.value?.department_id ===
                                user.value.department_id) &&
                        location.value.user_id === user.value.id
                ),
            defaultHidden: false,
            defaultVisible: () =>
                computed(() => location.value?.department_id !== null),
        },
    ];
}
