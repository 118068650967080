
import { defineComponent } from "@vue/runtime-core";
import CategoryTile from "./Tile.vue";
import type { TileConfig } from "@/domains/categories/types";

import DepartmentIcon from "@/assets/category-icons/department_icon.svg";
import BulletIcon from "@/assets/category-icons/bullet_icon.png";
import BloodIcon from "@/assets/category-icons/blood_icon.png";
import SettingsIcon from "@/assets/category-icons/settings_icon.png";
import PriceIcon from "@/assets/category-icons/euro.svg";
import WeatherIcon from "@/assets/category-icons/weather_icon.png";
import AimIcon from "@/assets/category-icons/aim_icon.png";
import UserIcon from "@/assets/category-icons/user_icon.png";
import BoarIcon from "@/assets/category-icons/boar_icon.png";
import DogIcon from "@/assets/category-icons/dog_icon.svg";
import WeaponIcon from "@/assets/category-icons/weapon_icon.svg";
import QuestionIcon from "@/assets/question.svg";
import { useRouter } from "vue-router";
import { useUser } from "@/domains/auth/user";
import { useMetaTitle } from "@/domains/app/pageMeta";

export default defineComponent({
    components: { CategoryTile },

    setup() {
        useMetaTitle().setTitle("Catégories");

        const categories: TileConfig[] = [
            {
                name: "Foire aux questions",
                img: QuestionIcon,
                route: "faqs.index",
            },
            {
                name: "Fiches",
                img: SettingsIcon,
                route: "sheets.index",
            },
            {
                name: "Chiens",
                img: DogIcon,
                route: "dogs.index",
            },
            {
                name: "Etats négatifs",
                img: SettingsIcon,
                route: "negative-states.index",
            },
            {
                name: "Indemnités KM",
                img: PriceIcon,
                route: "km-prices.index",
            },
            {
                name: "Races",
                img: DogIcon,
                route: "breeds.index",
            },
            {
                name: "Départements",
                img: DepartmentIcon,
                route: "departments.index",
            },
            {
                name: "Lieux",
                img: SettingsIcon,
                route: "locations.index",
            },
            {
                name: "GIC",
                img: SettingsIcon,
                route: "gics.index",
            },
            {
                name: "Demandeurs",
                img: UserIcon,
                route: "requesters.index",
            },
            {
                name: "Conducteurs",
                img: UserIcon,
                route: "users.index",
            },
            {
                name: "Gibiers",
                img: BoarIcon,
                route: "games.index",
            },
            {
                name: "Résultats",
                img: SettingsIcon,
                route: "results.index",
            },
            {
                name: "Armes",
                img: WeaponIcon,
                route: "weapons.index",
            },
            {
                name: "Types d'armes",
                img: WeaponIcon,
                route: "weapon-types.index",
            },
            {
                name: "Visées",
                img: AimIcon,
                route: "aims.index",
            },
            {
                name: "Tirs",
                img: AimIcon,
                route: "shots.index",
            },
            {
                name: "Types de chasse",
                img: DepartmentIcon,
                route: "hunts.index",
            },
            {
                name: "Traques",
                img: SettingsIcon,
                route: "hunts.index",
            },
            {
                name: "Poursuites",
                img: SettingsIcon,
                route: "pursuits.index",
            },
            {
                name: "Postes",
                img: SettingsIcon,
                route: "positions.index",
            },
            {
                name: "Traques",
                img: SettingsIcon,
                route: "hunt-types.index",
            },
            {
                name: "Calibres",
                img: BulletIcon,
                route: "calibres.index",
            },
            {
                name: "Munitions",
                img: BulletIcon,
                route: "ammunitions.index",
            },
            {
                name: "Allures",
                img: SettingsIcon,
                route: "paces.index",
            },
            {
                name: "Causes d'échec",
                img: SettingsIcon,
                route: "failures.index",
            },
            {
                name: "Blessures",
                img: BloodIcon,
                route: "injuries.index",
            },
            {
                name: "Météo",
                img: WeatherIcon,
                route: "weather.index",
            },
        ];

        const router = useRouter();
        const { canAccess } = useUser();

        return {
            categories,
            show: (route: string) => canAccess(router.resolve({ name: route })),
        };
    },
});
